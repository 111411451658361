import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import ArgonButton from "components/ArgonButton";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import { decode } from 'base-64';
import API from "Api/Api";
import MainLoading from "Helpers/MainLoading";
import { Link, useNavigate } from "react-router-dom";
import { AppColor } from "constants/AppColors";
import Loader from "react-js-loader";
import ArgonInput from "components/ArgonInput";
import { encode } from 'base-64';

function TransferHistory() {
  const [apiDataCall, setApiDataCall] = useState([])
  const [apiLoading, setApiLoading] = useState(false)
  const [endloading, setEndLoading] = useState(false)
  const [page, setPage] = useState(1);
  const queryParams = new URLSearchParams(window.location.search)
  const [total_amount, set_total_amount] = useState(0)
  const [total_subscribers, set_total_subscribers] = useState(0)
  const [startingDate, setStartingDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const completeRowsData = apiDataCall

  const WardsData = {
    columns: [
      { name: "amount", align: "left" },
      { name: "charges", align: "left" },
      { name: "transaction_id", align: "left" },
      { name: "account_number", align: "left" },
      { name: "created_at", align: "left" },
    ],

    rows: completeRowsData.map((item, idx) => {
      const data = {
        amount: <p style={{ paddingLeft: 20 }}>{Intl.NumberFormat().format(item.amount)}</p>,
        charges: Intl.NumberFormat().format(item.charges),
        transaction_id: item.transaction_id,
        account_number: item.account_number,
        created_at: item.created_at,
      }
      return data
    }),
  };

  const { columns, rows } = WardsData;

  const getApiData = () => {
    const startData = localStorage.getItem(`transfers_history_data_async_mjiwangu_${queryParams.get("active_status")}`)
    if (startData) {
      const _startData = JSON.parse(startData)
      setApiDataCall(_startData.data)
      set_total_amount(_startData.total_amount)
      set_total_subscribers(_startData.total_subscribers)
      setApiLoading(false)
      setEndLoading(false)
    }
    let url;
    if (queryParams.get("active_status")) {
      url = `${API.url}get-transfer-history?active_status=${queryParams.get("active_status")}&page=${page}&key=${API.key}&app_id=${API.app_id}`
    } else {
      url = `${API.url}get-transfer-history?page=${page}&key=${API.key}&app_id=${API.app_id}`
    }
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem(`transfers_history_data_async_mjiwangu_${queryParams.get("active_status")}`, JSON.stringify(data))

        const finalData = localStorage.getItem(`transfers_history_data_async_mjiwangu_${queryParams.get("active_status")}`)
        if (finalData) {
          const _finalData = JSON.parse(finalData)
          setApiDataCall(_finalData.data)
          set_total_amount(_finalData.total_amount)
          set_total_subscribers(_finalData.total_subscribers)
          setApiLoading(false)
          setEndLoading(false)
        }
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }



  const getFilterData = () => {
    if (startingDate === "" || endDate === "") {
      swal("Opps!", "Plase fill all required informations", "warning");
    } else {
      setApiLoading(true)
      let url;
      if (queryParams.get("active_status")) {
        url = `${API.url}get-transfer-history?active_status=${queryParams.get("active_status")}&page=${page}&key=${API.key}&app_id=${API.app_id}&start_date=${encode(startingDate)}&end_date=${encode(endDate)}`
      } else {
        url = `${API.url}get-transfer-history?page=${page}&key=${API.key}&app_id=${API.app_id}&start_date=${encode(startingDate)}&end_date=${encode(endDate)}`
      }
      fetch(url)
        .then(res => res.json())
        .then(data => {
          const _finalData = data
          setApiDataCall(_finalData.data)
          set_total_amount(_finalData.total_amount)
          set_total_subscribers(_finalData.total_subscribers)
          setApiLoading(false)
          setEndLoading(false)
        })
        .catch(err => {
          setApiLoading(false)
          swal("Opps!", err.message, "warning");
        })
    }
  }

  useEffect(() => {
    setApiLoading(true)
    getApiData()
  }, [page]);

  return (
    <>
      {apiLoading === true ? (<MainLoading />) : (
        <DashboardLayout>
          <DashboardNavbar />
          <ArgonBox py={3}>
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox display="flex" alignItems="center" p={3}>
                  <Link to={`/dashboard`}>
                    <ArgonButton style={{ marginLeft: 5 }} size="small" color={"primary"}>Back</ArgonButton>
                  </Link>

                  <ArgonTypography variant="h6" style={{ paddingLeft: 10 }}>{queryParams.get("active_status") && decode(queryParams.get("active_status"))} transfer History Table | Page {page}</ArgonTypography>
                </ArgonBox>

                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <div style={{ width: "42%" }}>
                    <ArgonTypography variant="button" color="text" fontWeight="medium">Starting Date</ArgonTypography>
                    <ArgonInput onChange={(e) => setStartingDate(e.target.value)} value={startingDate} type={"date"} placeholder="Enter Starting Date" />
                  </div>
                  <div style={{ width: "42%" }}>
                    <ArgonTypography variant="button" color="text" fontWeight="medium">End Date</ArgonTypography>
                    <ArgonInput type={"date"} onChange={(e) => setEndDate(e.target.value)} value={endDate} placeholder="Enter End Date" />
                  </div>

                  <div style={{ width: "10%" }}>
                    <ArgonTypography variant="button" color="text" fontWeight="medium">----</ArgonTypography>
                    <br />
                    <ArgonButton onClick={() => getFilterData()} color={"primary"}>Filter</ArgonButton>
                  </div>
                </ArgonBox>
                <hr />

                <ArgonBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table columns={columns} rows={rows} />
                </ArgonBox>
                <ArgonBox p={3}>
                  <ArgonTypography variant="button">Total Amount: TZS {Intl.NumberFormat().format(total_amount)}</ArgonTypography>
                  <br />
                  <ArgonTypography variant="button">Total Transactions: {Intl.NumberFormat().format(total_subscribers)}</ArgonTypography>
                </ArgonBox>
                <ArgonBox display="flex" p={3}>
                  <ArgonButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        if (page <= 1) {
                          setPage(1)
                          setEndLoading(false)
                        } else {
                          setPage(page - 1)
                        }
                      }, 1000);
                    }}
                  >
                    &laquo; prev
                  </ArgonButton>

                  <ArgonButton
                    style={{ marginLeft: 10 }}
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        setPage(page + 1)
                      }, 1000);
                    }}
                  >
                    next &raquo;
                  </ArgonButton>
                </ArgonBox>
                {endloading && (<Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />)}
              </Card>
            </ArgonBox>
          </ArgonBox>
          <Footer />
        </DashboardLayout>
      )}
    </>
  );
}

export default TransferHistory;
