import Dashboard from "layouts/dashboard";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faDashboard, faHistory, faImage, faImages, faLightbulb, faMobileAlt, faMoneyBill, faUserCircle, faUsers, faUsersLine, faUsersRectangle } from "@fortawesome/free-solid-svg-icons";
import Users from "layouts/users";
import UsersPosts from "layouts/usersPosts";
import ViewUserPost from "layouts/viewUserPost";
import SingleUsersPosts from "layouts/singleUserPosts";
import Categories from "layouts/categories";
import CategoryPosts from "layouts/categoryPosts";
import AccountsTypes from "layouts/accountsTypes";
import Subscriptions from "layouts/subscriptions";
import DemoSubscriptions from "layouts/demoSubscriptions";
import { AppColor } from "constants/AppColors";
import AppHomeImage from "layouts/AppHomeImage";
import AppVersion from "layouts/AppVersion";
import FreeBonusHistory from "layouts/freeBonusHistory";
import PostViews from "layouts/postViews";
import PurchaseHistory from "layouts/purchaseHistory";
import TransferHistory from "layouts/transferHistory";

const myStyle = { display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", border: `solid 2px ${AppColor}`, width: 28, height: 28, borderRadius: 6 }

const routes = [
  {
    type: "route",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    display: "YES",
    icon: <div style={myStyle}>
      <FontAwesomeIcon color={AppColor} icon={faDashboard} size="sm" />
    </div>,
    component: <Dashboard />,
  },

  { type: "title", title: "Setups", key: "Setups-pages", display: "YES" },
  {
    type: "route",
    name: "Categories",
    key: "categories",
    route: "/categories",
    display: "YES",
    icon: <div style={myStyle}>
      <FontAwesomeIcon color={AppColor} icon={faLightbulb} size="sm" />
    </div>,
    component: <Categories />,
  },
  {
    type: "route",
    name: "Accounts Types",
    key: "accounts-types",
    route: "/accounts-types",
    display: "YES",
    icon: <div style={myStyle}>
      <FontAwesomeIcon color={AppColor} icon={faUsersLine} size="sm" />
    </div>,
    component: <AccountsTypes />,
  },
  {
    type: "route",
    name: "App Home Image",
    key: "app-home-image",
    route: "/app-home-image",
    display: "YES",
    icon: <div style={myStyle}>
      <FontAwesomeIcon color={AppColor} icon={faImage} size="sm" />
    </div>,
    component: <AppHomeImage />,
  },
  {
    type: "route",
    name: "App Versions",
    key: "app-versions",
    route: "/app-versions",
    display: "YES",
    icon: <div style={myStyle}>
      <FontAwesomeIcon color={AppColor} icon={faMobileAlt} size="sm" />
    </div>,
    component: <AppVersion />,
  },

  { type: "title", title: "Subscriptions", key: "Subscriptions-pages", display: "YES" },
  {
    type: "route",
    name: "Purchases History",
    key: "purchases-history",
    route: "/purchases-history",
    display: "YES",
    icon: <div style={myStyle}>
      <FontAwesomeIcon color={AppColor} icon={faHistory} size="sm" />
    </div>,
    component: <PurchaseHistory />,
  },
  {
    type: "route",
    name: "Money Transfer History",
    key: "transfer-history",
    route: "/transfer-history",
    display: "YES",
    icon: <div style={myStyle}>
      <FontAwesomeIcon color={AppColor} icon={faMoneyBill} size="sm" />
    </div>,
    component: <TransferHistory />,
  },
  {
    type: "route",
    name: "Subscriptions",
    key: "accounts-subscription",
    route: "/accounts-subscription",
    display: "YES",
    icon: <div style={myStyle}>
      <FontAwesomeIcon color={AppColor} icon={faUsersRectangle} size="sm" />
    </div>,
    component: <Subscriptions />,
  },
  {
    type: "route",
    name: "Demo Subscriptions",
    key: "demo-subscription",
    route: "/demo-subscription",
    display: "YES",
    icon: <div style={myStyle}>
      <FontAwesomeIcon color={AppColor} icon={faCalendarDays} size="sm" />
    </div>,
    component: <DemoSubscriptions />,
  },
  {
    type: "route",
    name: "Free Bonus History",
    key: "bonus-subscription",
    route: "/bonus-subscription",
    display: "YES",
    icon: <div style={myStyle}>
      <FontAwesomeIcon color={AppColor} icon={faCalendarDays} size="sm" />
    </div>,
    component: <FreeBonusHistory />,
  },
  {
    type: "route",
    name: "Category Posts",
    key: "category-posts",
    route: "/category-posts",
    display: "NO",
    icon: <div style={myStyle}>
      <FontAwesomeIcon color={AppColor} icon={faLightbulb} size="sm" />
    </div>,
    component: <CategoryPosts />,
  },

  { type: "title", title: "Users", key: "users-pages", display: "YES" },
  {
    type: "route",
    name: "Users",
    key: "users",
    route: "/users",
    display: "YES",
    icon: <div style={myStyle}>
      <FontAwesomeIcon color={AppColor} icon={faUsers} size="sm" />
    </div>,
    component: <Users />,
  },

  { type: "title", title: "Posts", key: "posts-pages", display: "YES" },
  {
    type: "route",
    name: "Users' Posts",
    key: "users-posts",
    route: "/users-posts",
    display: "YES",
    icon: <div style={myStyle}>
      <FontAwesomeIcon color={AppColor} icon={faImages} size="sm" />
    </div>,
    component: <UsersPosts />,
  },
  {
    type: "route",
    name: "Post Views",
    key: "post-views",
    route: "/post-views",
    display: "NO",
    icon: <div style={myStyle}>
      <FontAwesomeIcon color={AppColor} icon={faImages} size="sm" />
    </div>,
    component: <PostViews />,
  },
  {
    type: "route",
    name: "View User Posts",
    key: "view-posts",
    route: "/view-posts",
    display: "NO",
    icon: <div style={myStyle}>
      <FontAwesomeIcon color={AppColor} icon={faImages} size="sm" />
    </div>,
    component: <ViewUserPost />,
  },
  {
    type: "route",
    name: "User Posts",
    key: "user-posts",
    route: "/user-posts",
    display: "NO",
    icon: <div style={myStyle}>
      <FontAwesomeIcon color={AppColor} icon={faImages} size="sm" />
    </div>,
    component: <SingleUsersPosts />,
  },
  // {
  //   type: "route",
  //   name: "Expenses",
  //   key: "expenses",
  //   route: "/expenses",
  //   display: "YES",
  //   icon: <FontAwesomeIcon color={AppColor} icon={faHandHoldingDollar} size="sm" />,
  //   component: <Expenses />,
  // },

  { type: "title", title: "Profile", key: "Profile-pages", display: "YES" },
  {
    type: "route",
    name: "Profile",
    key: "profile",
    route: "/profile",
    display: "YES",
    icon: <div style={myStyle}>
      <FontAwesomeIcon color={AppColor} icon={faUserCircle} size="sm" />
    </div>,
    component: <Profile />,
  },
  {
    type: "route",
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    display: "NO",
    icon: (
      <ArgonBox component="i" color="warning" fontSize="14px" className="ni ni-single-copy-04" />
    ),
    component: <SignIn />,
  },
  {
    type: "route",
    name: "Sign Up",
    key: "sign-up",
    route: "/authentication/sign-up",
    display: "NO",
    icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-collection" />,
    component: <SignUp />,
  },
];

export default routes;
