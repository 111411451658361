import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import ArgonButton from "components/ArgonButton";
import { useEffect, useState } from "react";
import Modal from 'react-modal';
import { modalSettings } from "constants/ModalSettins";
import swal from "sweetalert";
import { encode, decode } from 'base-64';
import API from "Api/Api";
import Loader from "react-js-loader";
import { AppColor } from "constants/AppColors";
import MainLoading from "Helpers/MainLoading";
import ArgonAvatar from "components/ArgonAvatar";
import GetUserRole from "../../functions/getUserRole";
import { Link, useNavigate } from "react-router-dom";

function PostViews() {
  const [registerModal, setRegisterModal] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [role, setRole] = useState("")
  const [password, setPassword] = useState("")
  const [apiDataCall, setApiDataCall] = useState([])
  const [apiDataCallSearch, setApiDataCallSearch] = useState([])
  const [apiLoading, setApiLoading] = useState(false)
  const [selectedUpdate, setSelectUpdate] = useState("")
  const [profileModal, setProfileModal] = useState(false)
  const [endloading, setEndLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [userPhoto, setUserPhoto] = useState("")
  const [publicProfile, setPublicProfile] = useState(null)
  const [platform, setplatform] = useState("")
  const [version, setversion] = useState("")
  const [appVersion, setappVersion] = useState("")

  const queryParams = new URLSearchParams(window.location.search)

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const completeRowsData = apiDataCallSearch.length > 0 ? apiDataCallSearch : apiDataCall

  const WardsData = {
    columns: [
      { name: "name", align: "left" },
      { name: "status", align: "left" },
      { name: "posts", align: "left" },
      { name: "last_seen", align: "center" },
      { name: "created_at", align: "center" },
      { name: "action", align: "center" },
    ],

    rows: completeRowsData.map((item, idx) => {
      const data = {
        name: <ArgonBox display="flex" alignItems="center" px={1} py={0.5}>
          <ArgonBox mr={2}>
            <ArgonAvatar src={item.photo.uri} alt={`${item.first_name[0]}`} size="sm" variant="rounded" />
          </ArgonBox>
          <ArgonBox display="flex" flexDirection="column">
            <ArgonTypography variant="button" fontWeight="medium">
              {`${item.first_name} ${item.last_name}`}
            </ArgonTypography>
            <ArgonTypography variant="caption" color="secondary">
              {item.email}
              <br />
              {item.phone_number}
              <br />
              <GetUserRole role={item.role} />
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>,
        status: <ArgonBox display="flex" flexDirection="column">
          <ArgonButton style={{ fontSize: 10, padding: 5 }} color={item.status === "AVAILABLE" ? "secondary" : "warning"} size="small">
            {item.status}
          </ArgonButton>
        </ArgonBox>,
        posts: Intl.NumberFormat().format(item.count_posts),
        created_at: item.created_at,
        last_seen: item.last_seen,

        action: (
          <>
            <ArgonButton
              style={{ marginLeft: 5 }}
              size="small" color="info"
              onClick={() => {
                setSelectUpdate(item.id)
                setFirstName(item.first_name)
                setLastName(item.last_name)
                setRole(item.role)
                setEmail(item.email)
                setPhoneNumber(item.phone_number)
                setRole(item.role)
                setUserPhoto(item.photo.uri)
                setPublicProfile(item.public_profile)
                setplatform(item.platform)
                setversion(item.version)
                setappVersion(item.app_version)
                setProfileModal(true)
              }} >
              View Profile
            </ArgonButton>
          </>
        ),
      }
      return data
    }),
  };

  const { columns, rows } = WardsData;

  const getApiData = () => {
    const startData = localStorage.getItem(`Post_Views_data_async_mjiwangu_${queryParams.get("post_id")}`)
    if (startData) {
      setApiDataCall(JSON.parse(startData))
      setApiLoading(false)
      setEndLoading(false)
    }

    const url = `${API.url}get-post-views/${queryParams.get("post_id")}?page=${page}&key=${API.key}&app_id=${API.app_id}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem(`Post_Views_data_async_mjiwangu_${queryParams.get("post_id")}`, JSON.stringify(data.data))

        const finalData = localStorage.getItem(`Post_Views_data_async_mjiwangu_${queryParams.get("post_id")}`)
        setApiDataCall(JSON.parse(finalData))

        setApiLoading(false)
        setEndLoading(false)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  useEffect(() => {
    setApiLoading(true)
    getApiData()
  }, [page]);

  const deleteUserFunction = (user_id) => {
    swal({
      title: "Hold on!",
      text: "Are you sure you want to proceed?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          deleteUserFunctionComplete(user_id)
        } else {
          swal("Action cancelled!");
        }
      });
  }

  const deleteUserFunctionComplete = (user_id) => {
    setApiLoading(true)
    const url = `${API.url}delete-user/${encode(user_id)}?key=${API.key}&app_id=${API.app_id}`
    fetch(url)
      .then(res => res.json())
      .then(res => {
        setApiLoading(false)
        getApiData()
        swal(res.result, res.message, "success");
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  const clearHooks = () => {
    setSelectUpdate("")
    setFirstName("")
    setLastName("")
    setPhoneNumber("")
    setPassword("")
    setEmail("")
    setUserPhoto("")
    setplatform("")
    setversion("")
    setappVersion("")
  }

  return (
    <>
      {apiLoading === true ? (<MainLoading />) : (
        <DashboardLayout>
          <DashboardNavbar />
          <ArgonBox py={3}>
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox display="flex" justifyContent="space-between" p={3}>
                  <ArgonBox display="flex" alignItems="center">
                    <Link to={decode(queryParams.get("next_url"))}>
                      <ArgonButton style={{ marginLeft: 5 }} size="small" color={"primary"}>&laquo; Back</ArgonButton>
                    </Link>

                    <ArgonTypography variant="h6" style={{ paddingLeft: 10 }}>Post Views [{decode(queryParams.get("name"))}] | Page {page}</ArgonTypography>
                  </ArgonBox>
                </ArgonBox>

                <ArgonBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table columns={columns} rows={rows} />
                </ArgonBox>
                <ArgonBox display="flex" p={3}>
                  <ArgonButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        if (page <= 1) {
                          setPage(1)
                          setEndLoading(false)
                        } else {
                          setPage(page - 1)
                        }
                      }, 1000);
                    }}
                  >
                    &laquo; prev
                  </ArgonButton>

                  <ArgonButton
                    style={{ marginLeft: 10 }}
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        setPage(page + 1)
                      }, 1000);
                    }}
                  >
                    next &raquo;
                  </ArgonButton>
                </ArgonBox>
                {endloading && (<Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />)}
              </Card>
            </ArgonBox>
          </ArgonBox>

          <Modal
            isOpen={profileModal}
            style={modalSettings}
            shouldCloseOnEsc={true}
            preventScroll={false}
            role="dialog"
          >
            <div style={{ float: "right" }}>
              <ArgonButton
                color="warning"
                onClick={() => {
                  clearHooks()
                  setProfileModal(false)
                }}
              >close</ArgonButton>
            </div>

            <ArgonBox>
              <ArgonBox display="flex">
                <div style={{ width: "25%", textAlign: "center", alignItems: "center", alignContent: "center", justifyContent: "center" }} >
                  <ArgonAvatar src={userPhoto} alt={"Image"} size="xxxl" variant="rounded" style={{ borderRadius: 100 }} />
                  <ArgonTypography variant="h5" fontWeight="medium">ID: {selectedUpdate}</ArgonTypography>
                </div>
                <div style={{ width: "75%" }}>
                  <ArgonBox display="flex" justifyContent="space-between">
                    <div style={{ width: "50%" }}>
                      <ArgonTypography variant="h5" fontWeight="medium">Personal Information</ArgonTypography>
                      <hr />
                      <ArgonTypography variant="button" color="text" fontWeight="medium">First Name: {firstName}</ArgonTypography>
                      <br />
                      <ArgonTypography variant="button" color="text" fontWeight="medium">Phone Number: {phoneNumber}</ArgonTypography>
                      <br />
                      <ArgonTypography variant="button" color="text" fontWeight="medium">Email Address: {email}</ArgonTypography>
                      <br />
                      <ArgonTypography variant="button" color="text" fontWeight="medium">Role: {role}</ArgonTypography>
                      <br />
                      <ArgonTypography variant="button" color="text" fontWeight="medium">Platform: {platform}</ArgonTypography>
                      <br />
                      <ArgonTypography variant="button" color="text" fontWeight="medium">Version: {version}</ArgonTypography>
                      <br />
                      <ArgonTypography variant="button" color="text" fontWeight="medium">App Version: {appVersion}</ArgonTypography>
                      <hr />

                      {publicProfile && (
                        <div>
                          <ArgonTypography variant="button" color="text" fontWeight="medium">Public Profile Images</ArgonTypography>

                          <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", width: "80%" }}>
                            {publicProfile.photos.map((item, idx) =>
                              <image key={idx} alt={item.uri} src={item.uri} style={{ width: "30%", height: 70, border: "solid 1px black", margin: 4, borderRadius: 6 }}></image>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    {publicProfile && (
                      <div style={{ width: "50%" }}>
                        <ArgonTypography variant="h5" fontWeight="medium">Public Profile</ArgonTypography>
                        <hr />
                        <ArgonTypography variant="button" color="text" fontWeight="medium">Business Name: {publicProfile.business_name}</ArgonTypography>
                        <br />
                        <ArgonTypography variant="button" color="text" fontWeight="medium">Email: {publicProfile.email}</ArgonTypography>
                        <br />
                        <ArgonTypography variant="button" color="text" fontWeight="medium">Phone Number: {publicProfile.phone_number}</ArgonTypography>
                        <br />
                        <ArgonTypography variant="button" color="text" fontWeight="medium">Region: {publicProfile.region}</ArgonTypography>
                        <br />
                        <ArgonTypography variant="button" color="text" fontWeight="medium">District: {publicProfile.district}</ArgonTypography>
                        <br />
                        <ArgonTypography variant="button" color="text" fontWeight="medium">Address: {publicProfile.address}</ArgonTypography>
                        <br />
                        <ArgonTypography variant="button" color="text" fontWeight="medium">Description: {publicProfile.details}</ArgonTypography>
                        <hr />
                        <ArgonTypography variant="h5" fontWeight="medium">Subscription Status</ArgonTypography>

                        <ArgonButton color={publicProfile.subscription.active_status === "AVAILABLE" ? "success" : "warning"}>{publicProfile.subscription.active_status}</ArgonButton>
                        <ArgonTypography variant="button">From: {publicProfile.subscription.starting_date} to: {publicProfile.subscription.expiry_date}</ArgonTypography>
                      </div>
                    )}
                  </ArgonBox>
                </div>
              </ArgonBox>
            </ArgonBox>
          </Modal>
          <Footer />
        </DashboardLayout>
      )}
    </>
  );
}

export default PostViews;
