// @mui material components
import Link from "@mui/material/Link";

// Argon Dashboard 2 MUI components
import ArgonButton from "components/ArgonButton";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI context
import { useArgonController } from "context";


function SidenavFooter() {
  const [controller] = useArgonController();
  const { miniSidenav, darkSidenav } = controller;

  const userLogoutFunction = () => {
    localStorage.removeItem("user_id")
    localStorage.removeItem("username")
    localStorage.removeItem("role")
    localStorage.removeItem("photo")
    localStorage.removeItem("phone_number")
    localStorage.removeItem("super_admin")
    // navigate(`/authentication/sign-in`)
    window.open(`/authentication/sign-in`, `_self`)
  }

  return (
    <ArgonBox opacity={miniSidenav ? 0 : 1} sx={{ transition: "opacity 200ms linear" }}>
      <ArgonBox position="relative" textAlign="center">
        <ArgonBox component="img" src={localStorage.getItem("photo")} alt={localStorage.getItem("username")[0]} width="30%" height="30%" />
        <ArgonBox
          width="100%"
          pb={2}
          px={2}
          color={darkSidenav ? "white" : "dark"}
          textAlign="center"
          lineHeight={0}
        >
          <ArgonTypography color="inherit" variant="h6">
            {localStorage.getItem("username")}
          </ArgonTypography>
          <ArgonTypography color="inherit" variant="caption">
            #{localStorage.getItem("user_id")}
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>
      <ArgonBox display="flex" flexDirection="column">
        <ArgonButton
          component={Link}
          href="#"
          color="warning"
          size="small"
          fullWidth
          sx={{ mb: 1 }}
          onClick={() => userLogoutFunction()}
        >
          Sign Out
        </ArgonButton>
      </ArgonBox>
    </ArgonBox>
  );
}

export default SidenavFooter;
