import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import ArgonButton from "components/ArgonButton";
import { useEffect, useState } from "react";
import ArgonInput from "components/ArgonInput";
import swal from "sweetalert";
import { encode, decode } from 'base-64';
import API from "Api/Api";
import Loader from "react-js-loader";
import { AppColor } from "constants/AppColors";
import MainLoading from "Helpers/MainLoading";
import ArgonAvatar from "components/ArgonAvatar";
import { Link, useNavigate } from "react-router-dom";

function CategoryPosts() {
  const [apiDataCall, setApiDataCall] = useState([])
  const [apiDataCallSearch, setApiDataCallSearch] = useState([])
  const [apiLoading, setApiLoading] = useState(false)
  const [endloading, setEndLoading] = useState(false)
  const [page, setPage] = useState(1);
  const queryParams = new URLSearchParams(window.location.search)

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const completeRowsData = apiDataCallSearch.length > 0 ? apiDataCallSearch : apiDataCall

  const WardsData = {
    columns: [
      { name: "post_name", align: "left" },
      { name: "region", align: "left" },
      { name: "district", align: "left" },
      { name: "address", align: "left" },
      { name: "created_at", align: "center" },
      { name: "action", align: "center" },
    ],

    rows: completeRowsData.map((item, idx) => {
      const data = {
        post_name: <ArgonBox display="flex" alignItems="center" px={1} py={0.5}>
          <ArgonBox mr={2}>
            <ArgonAvatar src={item.photo.uri} alt={`${item.name}`} size="sm" variant="rounded" />
          </ArgonBox>
          <ArgonBox display="flex" flexDirection="column">
            <ArgonTypography variant="button" fontWeight="medium">
              {`${item.name}`}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>,
        region: item.location.region,
        district: item.location.district,
        address: item.location.ward,
        created_at: item.created_at,

        action: (
          <>
            <Link to={`/view-posts?post_id=${encode(item.id)}&post_name=${encode(item.name)}&next_url=${encode(`category-posts?category_id=${queryParams.get('category_id')}&name=${queryParams.get('name')}&next_url=${queryParams.get('next_url')}`)}`}>
              <ArgonButton color="info" style={{ marginLeft: 5 }} size="small">View More</ArgonButton>
            </Link>

            <ArgonButton style={{ marginLeft: 5 }} size="small" color={"warning"} onClick={() => deletePostFunction(item.id)} >Delete</ArgonButton>
          </>
        ),
      }
      return data
    }),
  };

  const { columns, rows } = WardsData;

  const getApiData = () => {
    const startData = localStorage.getItem(`Category_Posts_data_async_mjiwangu_${queryParams.get("category_id")}`)
    if (startData) {
      setApiDataCall(JSON.parse(startData))
      setApiLoading(false)
      setEndLoading(false)
    }
    const url = `${API.url}get-posts-by-category/${queryParams.get("category_id")}/${encode(localStorage.getItem("user_id"))}?page=${page}&key=${API.key}&app_id=${API.app_id}&lang=en`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem(`Category_Posts_data_async_mjiwangu_${queryParams.get("category_id")}`, JSON.stringify(data.data))

        const finalData = localStorage.getItem(`Category_Posts_data_async_mjiwangu_${queryParams.get("category_id")}`)
        setApiDataCall(JSON.parse(finalData))

        setApiLoading(false)
        setEndLoading(false)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  useEffect(() => {
    setApiLoading(true)
    getApiData()
  }, [page]);

  const deletePostFunction = (post_id) => {
    swal({
      title: "Hold on!",
      text: "Are you sure you want to proceed?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          deletePostFunctionComplete(post_id)
        } else {
          swal("Action cancelled!");
        }
      });
  }

  const deletePostFunctionComplete = (post_id) => {
    setApiLoading(true)
    const url = `${API.url}delete-post/${encode(post_id)}?key=${API.key}&app_id=${API.app_id}`
    fetch(url)
      .then(res => res.json())
      .then(res => {
        setApiLoading(false)
        getApiData()
        swal(res.result, res.message, "success");
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  const searchUsersFunction = (search) => {
    const url = `${API.url}get-posts-by-category/${queryParams.get("category_id")}/${encode(localStorage.getItem("user_id"))}?page=${page}&key=${API.key}&app_id=${API.app_id}&lang=en&search=${encode(search)}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setApiDataCallSearch(data.data)
      })
      .catch(err => {
        setApiLoading(false)
        // swal("Opps!", err.message, "warning");
      })
  }

  return (
    <>
      {apiLoading === true ? (<MainLoading />) : (
        <DashboardLayout>
          <DashboardNavbar />
          <ArgonBox py={3}>
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox display="flex" justifyContent="space-" alignItems="center" p={3}>
                  <Link to={`/${decode(queryParams.get("next_url"))}`}>
                    <ArgonButton style={{ marginLeft: 5 }} size="small" color={"primary"}>Back</ArgonButton>
                  </Link>
                  <ArgonTypography variant="h6" style={{ marginLeft: 10 }}>{decode(queryParams.get("name"))} - Posts Table | Page {page}</ArgonTypography>
                </ArgonBox>
                <ArgonBox p={3}>
                  <ArgonInput
                    placeholder="Search Here..."
                    onChange={(e) => {
                      searchUsersFunction(e.target.value)
                    }}
                  />
                </ArgonBox>

                <ArgonBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table columns={columns} rows={rows} />
                </ArgonBox>
                <ArgonBox display="flex" p={3}>
                  <ArgonButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        if (page <= 1) {
                          setPage(1)
                          setEndLoading(false)
                        } else {
                          setPage(page - 1)
                        }
                      }, 1000);
                    }}
                  >
                    &laquo; prev
                  </ArgonButton>

                  <ArgonButton
                    style={{ marginLeft: 10 }}
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        setPage(page + 1)
                      }, 1000);
                    }}
                  >
                    next &raquo;
                  </ArgonButton>
                </ArgonBox>
                {endloading && (<Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />)}
              </Card>
            </ArgonBox>
          </ArgonBox>
          <Footer />
        </DashboardLayout>
      )}
    </>
  );
}

export default CategoryPosts;
