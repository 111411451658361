import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import ArgonButton from "components/ArgonButton";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import { encode, decode } from 'base-64';
import API from "Api/Api";
import MainLoading from "Helpers/MainLoading";
import { Link, useNavigate } from "react-router-dom";
import ArgonAvatar from "components/ArgonAvatar";

function ViewUserPost() {
  const [apiDataCall, setApiDataCall] = useState([])
  const [apiLoading, setApiLoading] = useState(false)
  const [currentPhoto, setCurrentPhoto] = useState("")
  const [postComments, setPostComments] = useState([])
  const queryParams = new URLSearchParams(window.location.search)

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const getApiData = () => {
    const startData = localStorage.getItem(`Single_Post_data_${queryParams.get("post_id")}`)
    if (startData) {
      setApiDataCall(JSON.parse(startData))
      setApiLoading(false)
    }
    const url = `${API.url}get-single-post/${queryParams.get("post_id")}/${encode(localStorage.getItem("user_id"))}?key=${API.key}&app_id=${API.app_id}&lang=en`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem(`Single_Post_data_${queryParams.get("post_id")}`, JSON.stringify(data.data))

        const finalData = localStorage.getItem(`Single_Post_data_${queryParams.get("post_id")}`)
        setApiDataCall(JSON.parse(finalData))

        setApiLoading(false)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  const getApiPostCommentsData = () => {
    const startData = localStorage.getItem(`Post_Comments_data_${queryParams.get("post_id")}`)
    if (startData) {
      setPostComments(JSON.parse(startData))
    }
    const url = `${API.url}get-product-comments/${queryParams.get("post_id")}?key=${API.key}&app_id=${API.app_id}&lang=en`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem(`Post_Comments_data_${queryParams.get("post_id")}`, JSON.stringify(data.data))

        const finalData = localStorage.getItem(`Post_Comments_data_${queryParams.get("post_id")}`)
        setPostComments(JSON.parse(finalData))
      })
      .catch(err => {
        swal("Opps!", err.message, "warning");
      })
  }

  useEffect(() => {
    setApiLoading(true)
    getApiData()
    getApiPostCommentsData()
  }, []);

  return (
    <>
      {apiLoading === true ? (<MainLoading />) : (
        <DashboardLayout>
          <DashboardNavbar />
          <ArgonBox py={3}>
            <ArgonBox mb={3}>
              {apiDataCall.map((item, index) =>
                <Card key={index}>
                  <ArgonBox display="flex" alignItems="center" p={3}>
                    <Link to={`/${decode(queryParams.get("next_url"))}`}>
                      <ArgonButton style={{ marginLeft: 5 }} size="small" color={"primary"}>&laquo; Back</ArgonButton>
                    </Link>

                    <ArgonTypography variant="h6" style={{ paddingLeft: 10 }}>{decode(queryParams.get("post_name"))}</ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    sx={{
                      "& .MuiTableRow-root:not(:last-child)": {
                        "& td": {
                          borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        },
                      },
                    }}
                  >
                    <ArgonBox display="flex" p={3}>
                      <div style={{ width: "40%" }}>
                        <div
                          style={{
                            backgroundImage: `url('${currentPhoto === "" ? item.photo.uri : currentPhoto}')`,
                            width: "100%",
                            height: 400,
                            border: "solid 1px black",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            borderRadius: 10
                          }}
                        ></div>
                        <ArgonBox display="flex" flexWrap="wrap" style={{ marginTop: 20 }} justifyContent="space-between" >
                          {item.photos.map((photo, kep) =>
                            <div
                              key={kep}
                              onClick={() => setCurrentPhoto(photo.uri)}
                              style={{
                                border: currentPhoto === photo.uri ? `solid 3px blue` : "none",
                                width: "15%",
                                height: 70,
                                borderRadius: 8,
                                cursor: "pointer"
                              }}>
                              <img src={photo.uri} style={{ width: "100%", height: "100%", borderRadius: 6 }}></img>
                            </div>
                          )}
                        </ArgonBox>

                      </div>
                      <div style={{ width: "60%" }}>
                        <ArgonBox style={{ paddingLeft: 20 }}>
                          <ArgonTypography variant="button">Name: {item.name} (#:{item.id})</ArgonTypography>
                          <br />
                          {item.price && (<>
                            <ArgonTypography variant="button">Price: TZS {Intl.NumberFormat().format(item.price)}</ArgonTypography>
                            <br />
                          </>)}
                          <ArgonTypography variant="button">Category: {item.category}</ArgonTypography>
                          <br />
                          <ArgonTypography variant="button">Physical Address: {item.location.region}, {item.location.district}, {item.location.ward}</ArgonTypography>
                          <br />
                          <ArgonBox display="flex">
                            <ArgonBox>
                              <ArgonTypography variant="button">Latitude: {item.location.latitude}</ArgonTypography>
                              <br />
                              <ArgonTypography variant="button">Longitude: {item.location.longitude}</ArgonTypography>
                            </ArgonBox>
                            <ArgonBox>
                              <ArgonButton onClick={() => window.open(`https://maps.google.com/?q=${item.location.latitude},${item.location.longitude}`, "_blank")} color="primary">View in Map</ArgonButton>
                            </ArgonBox>
                          </ArgonBox>
                          <hr />
                          <ArgonTypography variant="button">User Name: {item.contact.name}</ArgonTypography>
                          <br />
                          <ArgonTypography variant="button">User Phone: {item.contact.phone}</ArgonTypography>
                          <br />
                          <ArgonTypography variant="button">User Email: {item.contact.email}</ArgonTypography>
                          <br />
                          <ArgonTypography variant="button">Platform: {item.platform}</ArgonTypography>
                          <br />
                          <ArgonTypography variant="button">Version: {item.version}</ArgonTypography>
                          <br />
                          <ArgonTypography variant="button">App Platform: {item.app_version}</ArgonTypography>
                          <br />
                          <ArgonTypography variant="button">Subscription Status: <ArgonButton size="small" color={item.subscription.active_status !== "ACTIVE" ? "warning" : "success"}>{item.subscription.active_status}</ArgonButton></ArgonTypography>
                          <br />
                          <ArgonTypography variant="button">Display Status: <ArgonButton size="small" color={item.display_status !== "YES" ? "warning" : "success"}>{item.display_status}</ArgonButton></ArgonTypography>
                          <br />
                          <ArgonTypography variant="button">Valid From: {item.subscription.starting_date} to: {item.subscription.expiry_date}</ArgonTypography>
                        </ArgonBox>
                      </div>
                    </ArgonBox>

                    <ArgonBox display="flex" p={3}>
                      <ArgonBox style={{ width: "100%" }}>
                        <ArgonTypography variant="button">Description: {item.description}</ArgonTypography>
                        <hr />

                        <ArgonTypography variant="button">Features:</ArgonTypography>
                        <br />
                        {item.features.map((feature, kep) =>
                          <div key={kep}>
                            <ArgonTypography variant="button" style={{ margin: 2 }}> &raquo;{feature}</ArgonTypography>
                            <br />
                          </div>
                        )}
                      </ArgonBox>
                    </ArgonBox>

                    <ArgonBox display="flex" p={3}>
                      <ArgonBox style={{ width: "100%" }}>
                        <ArgonTypography variant="button">Comments:</ArgonTypography>
                        <hr />

                        {postComments.map((data, kep) =>
                          <div key={kep}>
                            <ArgonBox display="flex" alignItems="center" px={1} py={0.5}>
                              <ArgonBox mr={2}>
                                <ArgonAvatar src={data.photo} alt={`${data.full_name}`} size="sm" variant="rounded" />
                              </ArgonBox>
                              <ArgonBox display="flex" flexDirection="column">
                                <ArgonTypography variant="button" fontWeight="medium">
                                  {`${data.full_name}`}
                                </ArgonTypography>

                                <ArgonTypography variant="caption" color="secondary">
                                  {data.created_at}
                                  <br />
                                </ArgonTypography>
                              </ArgonBox>
                            </ArgonBox>
                            <ArgonTypography variant="caption" color="secondary">
                              {data.comment}
                              <br />
                            </ArgonTypography>
                            <hr style={{ opacity: 0.2 }} />
                          </div>
                        )}
                      </ArgonBox>
                    </ArgonBox>
                  </ArgonBox>
                </Card>
              )}
            </ArgonBox>
          </ArgonBox>
          <Footer />
        </DashboardLayout>
      )}
    </>
  );
}

export default ViewUserPost;
