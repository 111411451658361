import Grid from "@mui/material/Grid";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DetailedStatisticsCard from "examples/Cards/StatisticsCards/DetailedStatisticsCard";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
// Argon Dashboard 2 MUI base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import Slider from "layouts/dashboard/components/Slider";

// Data
import { useEffect, useState } from "react";
import API from "Api/Api";
import MainLoading from "Helpers/MainLoading";
import { Link, useNavigate } from "react-router-dom";
import { Card } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faCheckCircle, faClose, faLightbulb, faList, faUsers, faUsersLine } from "@fortawesome/free-solid-svg-icons";
import { faAndroid, faApple } from "@fortawesome/free-brands-svg-icons";
import { encode } from 'base-64';

function Default() {
  const [dashboardData, setDashboardData] = useState(null)
  const [graphData, setGraphData] = useState(null)

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const getApiData = () => {
    const getData = localStorage.getItem("Dashboard_data_async_mji_new_")
    if (getData) {
      const startData = JSON.parse(getData)
      const _data = {
        labels: [
          "Total Users",
          "iOS Users",
          "Android Users",
          "User's Posts",
          "Categories",
          "Subscription Account Types",
          "Active Subscriptions",
          "Expired Subscription",
          "Demo Subscription Accounts"
        ],
        datasets: [
          {
            label: "Items",
            color: "info",
            data: [
              startData.count_users,
              startData.count_ios_users,
              startData.count_android_users,
              startData.count_posts,
              startData.count_categories,
              startData.account_types,
              startData.count_active_subscription,
              startData.count_expired_subscription,
              startData.count_active_demo_accounts
            ],
          },
        ],
      };
      setGraphData(_data)
      setDashboardData(startData)
    }

    const url = `${API.url}get-dashboard-data?key=${API.key}&app_id=${API.app_id}`
    // console.log(url);
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem("Dashboard_data_async_mji_new_", JSON.stringify(data))
        const getData = localStorage.getItem("Dashboard_data_async_mji_new_")
        if (getData) {
          const completeData = JSON.parse(getData)
          setDashboardData(completeData)

          const data = {
            labels: [
              "Total Users",
              "iOS Users",
              "Android Users",
              "User's Posts",
              "Categories",
              "Subscription Account Types",
              "Active Subscriptions",
              "Expired Subscription",
              "Demo Subscription Accounts"
            ],
            datasets: [
              {
                label: "Items",
                color: "info",
                data: [
                  completeData.count_users,
                  completeData.count_ios_users,
                  completeData.count_android_users,
                  completeData.count_posts,
                  completeData.count_categories,
                  completeData.account_types,
                  completeData.count_active_subscription,
                  completeData.count_expired_subscription,
                  completeData.count_active_demo_accounts
                ],
              },
            ],
          };
          setGraphData(data)
        }
      })
      .catch(err => { })
  }

  const updateExpiredAccounts = () => {
    const Url = `${API.url}update_all_expired_accounts/${encode(localStorage.getItem("user_id"))}?app_id=${API.app_id}&key=${API.key}`
    fetch(Url)
      .then(res => res.json())
      .then(res => { })
      .catch(err => { })
  }

  useEffect(() => {
    getApiData()
    updateExpiredAccounts()
  }, []);

  const { size } = typography;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <>{!dashboardData ? (<MainLoading />) : (
        <>
          <ArgonBox py={3}>
            <Card style={{ padding: 10, boxShadow: `1px 4px 2px 4px lightgrey`, marginBottom: 20 }}>
              <Grid container spacing={1} mb={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <Link to={'/users'}>
                    <DetailedStatisticsCard
                      title="Total Users"
                      count={`${Intl.NumberFormat().format(dashboardData.count_users)}`}
                      icon={{ color: "info", component: <FontAwesomeIcon icon={faUsers} /> }}
                      percentage={{
                        color: "success", count: "", text: ""
                      }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <Link to={`/users?platform=${encode("ios")}`}>
                    <DetailedStatisticsCard
                      title="iOS Users"
                      count={`${Intl.NumberFormat().format(dashboardData.count_ios_users)}`}
                      icon={{ color: "info", component: <FontAwesomeIcon icon={faApple} /> }}
                      percentage={{
                        color: "success", count: "", text: ""
                      }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <Link to={`/users?platform=${encode("android")}`}>
                    <DetailedStatisticsCard
                      title="Android Users"
                      count={`${Intl.NumberFormat().format(dashboardData.count_android_users)}`}
                      icon={{ color: "info", component: <FontAwesomeIcon icon={faAndroid} /> }}
                      percentage={{
                        color: "success", count: "", text: ""
                      }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <Link to={'/users-posts'}>
                    <DetailedStatisticsCard
                      title="Users' Posts"
                      count={`${Intl.NumberFormat().format(dashboardData.count_posts)}`}
                      icon={{ color: "info", component: <FontAwesomeIcon icon={faList} /> }}
                      percentage={{
                        color: "success", count: "", text: ""
                      }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <Link to={'/categories'}>
                    <DetailedStatisticsCard
                      title="Categories"
                      count={`${Intl.NumberFormat().format(dashboardData.count_categories)}`}
                      icon={{ color: "info", component: <FontAwesomeIcon icon={faLightbulb} /> }}
                      percentage={{
                        color: "success", count: "", text: ""
                      }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <Link to={`/accounts-types`}>
                    <DetailedStatisticsCard
                      title="Account Types"
                      count={`${Intl.NumberFormat().format(dashboardData.account_types)}`}
                      icon={{ color: "info", component: <FontAwesomeIcon icon={faUsersLine} /> }}
                      percentage={{
                        color: "success", count: "", text: ""
                      }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <Link to={`/accounts-subscription?active_status=${encode("ACTIVE")}`}>
                    <DetailedStatisticsCard
                      title="Active Subscription"
                      count={`${Intl.NumberFormat().format(dashboardData.count_active_subscription)}`}
                      icon={{ color: "info", component: <FontAwesomeIcon icon={faCheckCircle} /> }}
                      percentage={{
                        color: "success", count: "", text: ""
                      }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <Link to={`/accounts-subscription?active_status=${encode("EXPIRED")}`}>
                    <DetailedStatisticsCard
                      title="Expired Subscription"
                      count={`${Intl.NumberFormat().format(dashboardData.count_expired_subscription)}`}
                      icon={{ color: "info", component: <FontAwesomeIcon icon={faClose} /> }}
                      percentage={{
                        color: "success", count: "", text: ""
                      }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <Link to={`/demo-subscription`}>
                    <DetailedStatisticsCard
                      title="Active Demo Subscription"
                      count={`${Intl.NumberFormat().format(dashboardData.count_active_demo_accounts)}`}
                      icon={{ color: "info", component: <FontAwesomeIcon icon={faCalendarDays} /> }}
                      percentage={{
                        color: "success", count: "", text: ""
                      }}
                    />
                  </Link>
                </Grid>

              </Grid>
            </Card>
            <Card style={{ padding: 10, boxShadow: `1px 4px 2px 4px lightgrey`, marginBottom: 20 }}>
              <Grid container spacing={3} mb={2}>
                <Grid item xs={2} lg={7}>
                  {graphData && (
                    <GradientLineChart
                      title="Overview"
                      description={
                        <ArgonBox display="flex" alignItems="center">
                          <ArgonBox fontSize={size.lg} color="success" mb={0.3} mr={0.5} lineHeight={0}>
                          </ArgonBox>
                          <ArgonTypography variant="button" color="text" fontWeight="medium">
                            {/* <ArgonTypography variant="button" color="text" fontWeight="regular">
                              in {new Date().getFullYear()}
                            </ArgonTypography> */}
                          </ArgonTypography>
                        </ArgonBox>
                      }
                      chart={graphData}
                    />
                  )}
                </Grid>
                <Grid item xs={12} lg={5}>
                  <Slider />
                </Grid>
              </Grid>
            </Card>
            {/* <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <SalesTable title="Sales by Country" rows={salesTableData} />
          </Grid>
          <Grid item xs={12} md={4}>
            <CategoriesList title="categories" categories={categoriesListData} />
          </Grid>
        </Grid> */}
          </ArgonBox>
          <Footer />
        </>
      )}</>
    </DashboardLayout>
  );
}

export default Default;
