import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import ArgonButton from "components/ArgonButton";
import { useEffect, useState } from "react";
import Modal from 'react-modal';
import { modalSettings } from "constants/ModalSettins";
import ArgonInput from "components/ArgonInput";
import swal from "sweetalert";
import { encode } from 'base-64';
import API from "Api/Api";
import Loader from "react-js-loader";
import { AppColor } from "constants/AppColors";
import MainLoading from "Helpers/MainLoading";
import { Link, useNavigate } from "react-router-dom";
import { todayDate } from "constants/StaticDatas";
import { SelectStyle } from "constants/SelectStyle";

function AccountsTypes() {
  const [registerModal, setRegisterModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [apiDataCall, setApiDataCall] = useState([])
  const [apiDataCallSearch, setApiDataCallSearch] = useState([])
  const [apiLoading, setApiLoading] = useState(false)
  const [selectedUpdate, setSelectUpdate] = useState("")
  const [accountName, setaccountName] = useState("")
  const [accountKey, setaccountKey] = useState("")
  const [swahiliDetails, setswahiliDetails] = useState("")
  const [englishDetails, setenglishDetails] = useState("")
  const [midPrice, setmidPrice] = useState("")
  const [price, setprice] = useState("")
  const [recomendedStatus, setRecomendedStatus] = useState("")

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const completeRowsData = apiDataCallSearch.length > 0 ? apiDataCallSearch : apiDataCall

  const WardsData = {
    columns: [
      { name: "sn", align: "left" },
      { name: "name", align: "left" },
      { name: "active_subscribers", align: "left" },
      { name: "details", align: "left" },
      { name: "price", align: "left" },
      { name: "key_word", align: "left" },
      { name: "recomended_status", align: "left" },
      // { name: "registered_by", align: "left" },
      { name: "created_at", align: "center" },
      { name: "action", align: "center" },
    ],

    rows: completeRowsData.map((item, idx) => {
      const data = {
        sn: <span style={{ padding: 8 }}>{++idx}</span>,
        name: item.name,
        active_subscribers: Intl.NumberFormat().format(item.count_subscribers),
        details: <div>
          <span>Swahili: {item.details_sw}</span>
          <br />
          <span>English: {item.details_eng}</span>
        </div>,
        price: <div>
          <span>Month ({item.one_month_days}): TZS {Intl.NumberFormat().format(item.price)}</span>
          <br />
          <span>Six Months ({item.six_months_days}): TZS {Intl.NumberFormat().format(item.mid_price)}</span>
          <br />
          <span>Discount: {item.discount}</span>
        </div>,
        key_word: <div>
          <span>{item.account_type}</span>
        </div>,
        recomended_status: item.recomended_status,
        // registered_by: item.registered_by,
        created_at: item.created_at,

        action: (
          <>
            <ArgonButton
              style={{ marginLeft: 5 }}
              size="small" color="info"
              onClick={() => {
                setSelectUpdate(item.id)
                setaccountName(item.name)
                setaccountKey(item.account_type)
                setswahiliDetails(item.details_sw)
                setenglishDetails(item.details_eng)
                setmidPrice(item.mid_price)
                setprice(item.price)
                setRecomendedStatus(item.recomended_status)
                setRegisterModal(true)
              }} >
              Update
            </ArgonButton>

            <ArgonButton style={{ marginLeft: 5 }} size="small" color={"warning"} onClick={() => deleteCategoryFunction(item.id)} >Delete</ArgonButton>
          </>
        ),
      }
      return data
    }),
  };

  const { columns, rows } = WardsData;

  const getApiData = () => {
    const startData = localStorage.getItem("Account_Types_data_async_mjiwangu_")
    if (startData) {
      setApiDataCall(JSON.parse(startData))
      setApiLoading(false)
    }

    const url = `${API.url}get_all_subscription_accounts/${encode(localStorage.getItem("user_id"))}?key=${API.key}&app_id=${API.app_id}&lang=${encode("en")}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem("Account_Types_data_async_mjiwangu_", JSON.stringify(data))

        const finalData = localStorage.getItem("Account_Types_data_async_mjiwangu_")
        setApiDataCall(JSON.parse(finalData))

        setApiLoading(false)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  useEffect(() => {
    setApiLoading(true)
    getApiData()
  }, []);

  const deleteCategoryFunction = (account_id) => {
    swal({
      title: "Hold on!",
      text: "Are you sure you want to proceed?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          deleteCategoryFunctionComplete(account_id)
        } else {
          swal("Action cancelled!");
        }
      });
  }

  const deleteCategoryFunctionComplete = (account_id) => {
    setApiLoading(true)
    const url = `${API.url}delete-account-type/${encode(account_id)}?key=${API.key}&app_id=${API.app_id}`
    fetch(url)
      .then(res => res.json())
      .then(res => {
        setApiLoading(false)
        getApiData()
        swal(res.result, res.message, "success");
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  const registerCategoryFunction = () => {
    if (accountKey === "" || accountName === "" || swahiliDetails === "" || englishDetails === "" || midPrice === "" || price === "" || recomendedStatus === "") {
      swal("Opps!", "Please fill all required informations", "warning");
    } else {
      setIsLoading(true)
      setTimeout(() => {
        const formData = new FormData()
        formData.append("key_word", encode(accountKey))
        formData.append("name", encode(accountName))
        formData.append("details", swahiliDetails)
        formData.append("english_details", englishDetails)
        formData.append("recomended_status", encode(recomendedStatus))
        formData.append("mid_price", encode(midPrice))
        formData.append("price", encode(price))
        formData.append("registered_by", encode(localStorage.getItem("user_id")))
        fetch(`${API.url}register-account-type?key=${API.key}&app_id=${API.app_id}`, { method: "POST", body: formData })
          .then(res => res.json())
          .then(res => {
            getApiData()
            setRegisterModal(false)
            setIsLoading(false)
            if (res.result === "SUCCESS") {
              clearHooks()
              swal(res.result, res.message, "success")
            } else {
              swal(res.result, res.message, "warning")
            }
          })
          .catch(err => {
            setIsLoading(false)
            swal("Opps!", err.message, "warning");
          })
      }, 1000);
    }
  }

  const updateCategoryFunction = () => {
    if (accountKey === "" || accountName === "" || swahiliDetails === "" || englishDetails === "" || midPrice === "" || price === "" || recomendedStatus === "") {
      swal("Opps!", "Please fill all required informations", "warning");
    } else {
      setIsLoading(true)
      setTimeout(() => {
        const formData = new FormData()
        formData.append("account_id", encode(selectedUpdate))
        formData.append("key_word", encode(accountKey))
        formData.append("name", encode(accountName))
        formData.append("details", swahiliDetails)
        formData.append("english_details", englishDetails)
        formData.append("recomended_status", encode(recomendedStatus))
        formData.append("mid_price", encode(midPrice))
        formData.append("price", encode(price))
        formData.append("updated_by", encode(localStorage.getItem("user_id")))
        fetch(`${API.url}update-account-type?key=${API.key}&app_id=${API.app_id}`, { method: "PUT", body: formData })
          .then(res => res.json())
          .then(res => {
            getApiData()
            setRegisterModal(false)
            setIsLoading(false)
            if (res.result === "SUCCESS") {
              clearHooks()
              swal(res.result, res.message, "success")
            } else {
              swal(res.result, res.message, "warning")
            }
          })
          .catch(err => {
            setIsLoading(false)
            swal("Opps!", err.message, "warning");
          })
      }, 1000);
    }
  }

  const clearHooks = () => {
    setSelectUpdate("")
    setaccountName("")
    setaccountKey("")
    setswahiliDetails("")
    setenglishDetails("")
    setmidPrice("")
    setRecomendedStatus("")
    setprice("")
    setRegisterModal(false)
  }

  return (
    <>
      {apiLoading === true ? (<MainLoading />) : (
        <DashboardLayout>
          <DashboardNavbar />
          <ArgonBox py={3}>
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <ArgonTypography variant="h6">Subscription Accounts Types</ArgonTypography>
                  <ArgonButton onClick={() => setRegisterModal(true)} color="info" size="small">
                    Add Account
                  </ArgonButton>
                </ArgonBox>

                <ArgonBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table columns={columns} rows={rows} />
                </ArgonBox>
              </Card>
            </ArgonBox>
          </ArgonBox>

          <Modal
            isOpen={registerModal}
            style={modalSettings}
            shouldCloseOnEsc={true}
            preventScroll={false}
            role="dialog"
          >
            <div style={{ float: "right" }}>
              <ArgonButton
                color="warning"
                onClick={() => {
                  clearHooks()
                  setRegisterModal(false)
                }}
              >close</ArgonButton>
            </div>
            <br />
            <h2>{selectedUpdate === "" ? "Register Account" : "Update Account"}</h2>
            <br />
            <ArgonBox display="flex" justifyContent="space-between" alignItems="center">
              <div style={{ width: "33%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">Account Name</ArgonTypography>
                <ArgonInput onChange={(e) => setaccountName(e.target.value)} value={accountName} placeholder="Enter Account Name" />
              </div>

              <div style={{ width: "33%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">Account Key Word</ArgonTypography>
                <ArgonInput
                  onChange={(e) => {
                    setaccountKey(e.target.value)
                  }}
                  value={accountKey}
                  placeholder="Enter Account Key Word"
                />
              </div>

              <div style={{ width: "33%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">Account Recomeded Status</ArgonTypography>
                <select style={SelectStyle}
                  onChange={(e) => {
                    setRecomendedStatus(e.target.value)
                  }}
                  value={recomendedStatus}
                >
                  <option value={recomendedStatus}>{recomendedStatus}</option>
                  <option value={"YES"}>YES</option>
                  <option value={"NO"}>NO</option>
                </select>
              </div>
            </ArgonBox>
            <ArgonBox display="flex" justifyContent="space-between" alignItems="center">
              <div style={{ width: "49%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">One Month Price</ArgonTypography>
                <ArgonInput onChange={(e) => setprice(e.target.value)} value={price} placeholder="Enter One Month Price" />
              </div>

              <div style={{ width: "49%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">Six Months Price</ArgonTypography>
                <ArgonInput
                  onChange={(e) => {
                    setmidPrice(e.target.value)
                  }}
                  value={midPrice}
                  placeholder="Enter Six Months Price"
                />
              </div>
            </ArgonBox>
            <br />
            <ArgonBox display="flex" justifyContent="space-between" alignItems="center">
              <div style={{ width: "49%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">Swahili Details</ArgonTypography>
                <br />
                <textarea rows={8} style={{ width: "100%", padding: 10, borderRadius: 6 }} onChange={(e) => setswahiliDetails(e.target.value)} placeholder="Enter Swahili Details" >
                  {swahiliDetails}
                </textarea>
              </div>
              <div style={{ width: "49%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">English Details</ArgonTypography>
                <br />
                <textarea style={{ width: "100%", padding: 10, borderRadius: 6 }} onChange={(e) => setenglishDetails(e.target.value)} rows={8} value={englishDetails} placeholder="Enter English Details">
                  {englishDetails}
                </textarea>
              </div>
            </ArgonBox>
            <br />
            {isLoading === true ? (
              <Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />
            ) : (
              <>
                {selectedUpdate !== "" ? (
                  <ArgonButton onClick={() => updateCategoryFunction()} color="secondary">Update</ArgonButton>
                ) : (
                  <ArgonButton onClick={() => registerCategoryFunction()} color="primary">Register</ArgonButton>
                )}
              </>
            )}
          </Modal>
          <Footer />
        </DashboardLayout>
      )}
    </>
  );
}

export default AccountsTypes;