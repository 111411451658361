import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import ArgonButton from "components/ArgonButton";
import { useEffect, useState } from "react";
import Modal from 'react-modal';
import { modalSettings } from "constants/ModalSettins";
import ArgonInput from "components/ArgonInput";
import swal from "sweetalert";
import { encode } from 'base-64';
import API from "Api/Api";
import Loader from "react-js-loader";
import { AppColor } from "constants/AppColors";
import MainLoading from "Helpers/MainLoading";
import { useNavigate } from "react-router-dom";
import ArgonAvatar from "components/ArgonAvatar";

function AppHomeImage() {
  const [registerModal, setRegisterModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [apiDataCall, setApiDataCall] = useState([])
  const [apiDataCallSearch, setApiDataCallSearch] = useState([])
  const [apiLoading, setApiLoading] = useState(false)
  const [swahiliName, setswahiliName] = useState("")
  const [englishName, setenglishName] = useState("")
  const [photo, setPhoto] = useState("")

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const completeRowsData = apiDataCallSearch.length > 0 ? apiDataCallSearch : apiDataCall

  const WardsData = {
    columns: [
      { name: "sn", align: "left" },
      { name: "title", align: "left" },
      { name: "photo", align: "left" },
      { name: "created_at", align: "center" },
      { name: "action", align: "center" },
    ],

    rows: completeRowsData.map((item, idx) => {
      const data = {
        sn: <span style={{ padding: 8 }}>{++idx}</span>,
        title: <div>
          <span>Swahili: <ArgonTypography variant="button">{item.title_sw}</ArgonTypography></span>
          <br />
          <span>English: <ArgonTypography variant="button">{item.title_eng}</ArgonTypography></span>
        </div>,
        photo: <div>
          <ArgonAvatar src={item.photo} alt={`${item.title_sw[0]}`} size="lg" variant="rounded" />
        </div>,
        // registered_by: item.registered_by,
        created_at: item.created_at,

        action: (
          <>
            <ArgonButton style={{ marginLeft: 5 }} size="small" color={"warning"} onClick={() => deleteCategoryFunction(item.id)} >Delete</ArgonButton>
          </>
        ),
      }
      return data
    }),
  };

  const { columns, rows } = WardsData;

  const getApiData = () => {
    const startData = localStorage.getItem("App_Home_Images_data_async_mjiwangu")
    if (startData) {
      setApiDataCall(JSON.parse(startData))
      setApiLoading(false)
    }

    const url = `${API.url}get-all-app-home-images?key=${API.key}&app_id=${API.app_id}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem("App_Home_Images_data_async_mjiwangu", JSON.stringify(data.data))

        const finalData = localStorage.getItem("App_Home_Images_data_async_mjiwangu")
        setApiDataCall(JSON.parse(finalData))

        setApiLoading(false)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  useEffect(() => {
    setApiLoading(true)
    getApiData()
  }, []);

  const deleteCategoryFunction = (category_id) => {
    swal({
      title: "Hold on!",
      text: "Are you sure you want to proceed?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          deleteCategoryFunctionComplete(category_id)
        } else {
          swal("Action cancelled!");
        }
      });
  }

  const deleteCategoryFunctionComplete = (category_id) => {
    setApiLoading(true)
    const url = `${API.url}delete-app-home-image/${encode(category_id)}?key=${API.key}&app_id=${API.app_id}`
    fetch(url)
      .then(res => res.json())
      .then(res => {
        setApiLoading(false)
        getApiData()
        swal(res.result, res.message, "success");
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  const registerCategoryFunction = () => {
    if (englishName === "" || swahiliName === "" || photo === "") {
      swal("Opps!", "Please fill all required informations", "warning");
    } else {
      setIsLoading(true)
      setTimeout(() => {
        const formData = new FormData()
        formData.append("photo", photo)
        formData.append("title_sw", encode(swahiliName))
        formData.append("title_eng", encode(englishName))
        formData.append("registered_by", encode(localStorage.getItem("user_id")))
        formData.append("updated_by", encode(localStorage.getItem("user_id")))
        fetch(`${API.url}register-app-home-image?key=${API.key}&app_id=${API.app_id}`, { method: "POST", body: formData })
          .then(res => res.json())
          .then(res => {
            getApiData()
            setRegisterModal(false)
            setIsLoading(false)
            if (res.result === "SUCCESS") {
              clearHooks()
              swal(res.result, res.message, "success")
            } else {
              swal(res.result, res.message, "warning")
            }
          })
          .catch(err => {
            setIsLoading(false)
            swal("Opps!", err.message, "warning");
          })
      }, 1000);
    }
  }

  const searchCategoriesFunction = (search) => {
    const url = `${API.url}get-all-app-home-images?search=${encode(search)}&key=${API.key}&app_id=${API.app_id}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setApiDataCallSearch(data.data)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  const clearHooks = () => {
    setswahiliName("")
    setenglishName("")
    setRegisterModal(false)
  }

  return (
    <>
      {apiLoading === true ? (<MainLoading />) : (
        <DashboardLayout>
          <DashboardNavbar />
          <ArgonBox py={3}>
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <ArgonTypography variant="h6">App Home Image</ArgonTypography>
                  <ArgonButton onClick={() => setRegisterModal(true)} color="info" size="small">
                    Add New Image
                  </ArgonButton>
                </ArgonBox>
                <ArgonBox p={3}>
                  <ArgonInput
                    placeholder="Search Here..."
                    onChange={(e) => {
                      searchCategoriesFunction(e.target.value)
                    }}
                  />
                </ArgonBox>

                <ArgonBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table columns={columns} rows={rows} />
                </ArgonBox>
              </Card>
            </ArgonBox>
          </ArgonBox>

          <Modal
            isOpen={registerModal}
            style={modalSettings}
            shouldCloseOnEsc={true}
            preventScroll={false}
            role="dialog"
          >
            <div style={{ float: "right" }}>
              <ArgonButton
                color="warning"
                onClick={() => {
                  clearHooks()
                  setRegisterModal(false)
                }}
              >close</ArgonButton>
            </div>
            <br />
            <h2>{"Register New Image"}</h2>
            <br />
            <ArgonBox display="flex" justifyContent="space-between" alignItems="center">
              <div style={{ width: "49%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">Swahili Title</ArgonTypography>
                <ArgonInput onChange={(e) => setswahiliName(e.target.value)} value={swahiliName} placeholder="Enter Swahili Title" />
              </div>

              <div style={{ width: "49%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">English Title</ArgonTypography>
                <ArgonInput
                  onChange={(e) => {
                    setenglishName(e.target.value)
                  }}
                  value={englishName}
                  placeholder="Enter English Title"
                />
              </div>
            </ArgonBox>
            <br />
            <ArgonBox display="flex" justifyContent="space-between" alignItems="center">
              <div style={{ width: "49%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">Image</ArgonTypography>
                <ArgonInput onChange={(e) => setPhoto(e.target.files[0])} type="file" placeholder="Enter Image" />
              </div>
            </ArgonBox>
            <br />
            {isLoading === true ? (
              <Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />
            ) : (
              <>
                <ArgonButton onClick={() => registerCategoryFunction()} color="primary">Register</ArgonButton>
              </>
            )}
          </Modal>
          <Footer />
        </DashboardLayout>
      )}
    </>
  );
}

export default AppHomeImage;
