import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import ArgonButton from "components/ArgonButton";
import { useEffect, useState } from "react";
import Modal from 'react-modal';
import { modalSettings } from "constants/ModalSettins";
import ArgonInput from "components/ArgonInput";
import swal from "sweetalert";
import { encode } from 'base-64';
import API from "Api/Api";
import Loader from "react-js-loader";
import { AppColor } from "constants/AppColors";
import MainLoading from "Helpers/MainLoading";
import { Link, useNavigate } from "react-router-dom";
import { todayDate } from "constants/StaticDatas";

function Categories() {
  const [registerModal, setRegisterModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [apiDataCall, setApiDataCall] = useState([])
  const [apiDataCallSearch, setApiDataCallSearch] = useState([])
  const [apiLoading, setApiLoading] = useState(false)
  const [selectedUpdate, setSelectUpdate] = useState("")
  const [swahiliName, setswahiliName] = useState("")
  const [englishName, setenglishName] = useState("")
  const [swahiliDetails, setswahiliDetails] = useState("")
  const [englishDetails, setenglishDetails] = useState("")

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const completeRowsData = apiDataCallSearch.length > 0 ? apiDataCallSearch : apiDataCall

  const WardsData = {
    columns: [
      { name: "sn", align: "left" },
      { name: "name", align: "left" },
      { name: "details", align: "left" },
      { name: "available_posts", align: "left" },
      // { name: "registered_by", align: "left" },
      { name: "created_at", align: "center" },
      { name: "action", align: "center" },
    ],

    rows: completeRowsData.map((item, idx) => {
      const data = {
        sn: <span style={{ padding: 8 }}>{++idx}</span>,
        name: <div>
          <span>Swahili: {item.name}</span>
          <br />
          <span>English: {item.name_eng}</span>
        </div>,
        details: <div>
          <span>Swahili: {item.details}</span>
          <br />
          <span>English: {item.details_eng}</span>
        </div>,
        available_posts: <div>
          <span>{Intl.NumberFormat().format(item.count_posts)}</span>
        </div>,
        // registered_by: item.registered_by,
        created_at: item.created_at,

        action: (
          <>
            <ArgonButton
              style={{ marginLeft: 5 }}
              size="small" color="info"
              onClick={() => {
                setSelectUpdate(item.id)
                setswahiliName(item.name)
                setenglishName(item.name_eng)
                setswahiliDetails(item.details)
                setenglishDetails(item.details_eng)
                setRegisterModal(true)
              }} >
              Update
            </ArgonButton>

            <Link to={`/category-posts?category_id=${encode(item.id)}&name=${encode(`${item.name}`)}&next_url=${encode("categories")}`}>
              <ArgonButton style={{ marginLeft: 5 }} size="small" color={"secondary"} >View Posts</ArgonButton>
            </Link>

            <ArgonButton style={{ marginLeft: 5 }} size="small" color={"warning"} onClick={() => deleteCategoryFunction(item.id)} >Delete</ArgonButton>
          </>
        ),
      }
      return data
    }),
  };

  const { columns, rows } = WardsData;

  const getApiData = () => {
    const startData = localStorage.getItem("Categories_data_async_mjiwangu")
    if (startData) {
      setApiDataCall(JSON.parse(startData))
      setApiLoading(false)
    }

    const url = `${API.url}get-all-categories?key=${API.key}&app_id=${API.app_id}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem("Categories_data_async_mjiwangu", JSON.stringify(data.data))

        const finalData = localStorage.getItem("Categories_data_async_mjiwangu")
        setApiDataCall(JSON.parse(finalData))

        setApiLoading(false)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  useEffect(() => {
    setApiLoading(true)
    getApiData()
  }, []);

  const deleteCategoryFunction = (category_id) => {
    swal({
      title: "Hold on!",
      text: "Are you sure you want to proceed?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          deleteCategoryFunctionComplete(category_id)
        } else {
          swal("Action cancelled!");
        }
      });
  }

  const deleteCategoryFunctionComplete = (category_id) => {
    setApiLoading(true)
    const url = `${API.url}delete-category/${encode(category_id)}?key=${API.key}&app_id=${API.app_id}`
    fetch(url)
      .then(res => res.json())
      .then(res => {
        setApiLoading(false)
        getApiData()
        swal(res.result, res.message, "success");
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  const registerCategoryFunction = () => {
    if (englishName === "" || swahiliName === "" || swahiliDetails === "" || englishDetails === "") {
      swal("Opps!", "Please fill all required informations", "warning");
    } else {
      setIsLoading(true)
      setTimeout(() => {
        const formData = new FormData()
        formData.append("created_at", encode(todayDate))
        formData.append("name", encode(swahiliName))
        formData.append("details", encode(swahiliDetails))
        formData.append("name_eng", encode(englishName))
        formData.append("details_eng", encode(englishDetails))
        formData.append("registered_by", encode(localStorage.getItem("user_id")))
        formData.append("updated_by", encode(localStorage.getItem("user_id")))
        fetch(`${API.url}register-category?key=${API.key}&app_id=${API.app_id}`, { method: "POST", body: formData })
          .then(res => res.json())
          .then(res => {
            getApiData()
            setRegisterModal(false)
            setIsLoading(false)
            if (res.result === "SUCCESS") {
              clearHooks()
              swal(res.result, res.message, "success")
            } else {
              swal(res.result, res.message, "warning")
            }
          })
          .catch(err => {
            setIsLoading(false)
            swal("Opps!", err.message, "warning");
          })
      }, 1000);
    }
  }

  const updateCategoryFunction = () => {
    if (englishName === "" || swahiliName === "" || swahiliDetails === "" || englishDetails === "") {
      swal("Opps!", "Please fill all required informations", "warning");
    } else {
      setIsLoading(true)
      setTimeout(() => {
        const formData = new FormData()
        formData.append("category_id", encode(selectedUpdate))
        formData.append("name", encode(swahiliName))
        formData.append("details", encode(swahiliDetails))
        formData.append("name_eng", encode(englishName))
        formData.append("details_eng", encode(englishDetails))
        formData.append("updated_by", encode(localStorage.getItem("user_id")))
        fetch(`${API.url}update-category?key=${API.key}&app_id=${API.app_id}`, { method: "PUT", body: formData })
          .then(res => res.json())
          .then(res => {
            getApiData()
            setRegisterModal(false)
            setIsLoading(false)
            if (res.result === "SUCCESS") {
              clearHooks()
              swal(res.result, res.message, "success")
            } else {
              swal(res.result, res.message, "warning")
            }
          })
          .catch(err => {
            setIsLoading(false)
            swal("Opps!", err.message, "warning");
          })
      }, 1000);
    }
  }

  const searchCategoriesFunction = (search) => {
    const url = `${API.url}get-all-categories?search=${encode(search)}&key=${API.key}&app_id=${API.app_id}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setApiDataCallSearch(data.data)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  const clearHooks = () => {
    setSelectUpdate("")
    setswahiliName("")
    setenglishName("")
    setswahiliDetails("")
    setenglishDetails("")
    setRegisterModal(false)
  }

  return (
    <>
      {apiLoading === true ? (<MainLoading />) : (
        <DashboardLayout>
          <DashboardNavbar />
          <ArgonBox py={3}>
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <ArgonTypography variant="h6">Categories</ArgonTypography>
                  <ArgonButton onClick={() => setRegisterModal(true)} color="info" size="small">
                    Add Category
                  </ArgonButton>
                </ArgonBox>
                <ArgonBox p={3}>
                  <ArgonInput
                    placeholder="Search Here..."
                    onChange={(e) => {
                      searchCategoriesFunction(e.target.value)
                    }}
                  />
                </ArgonBox>

                <ArgonBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table columns={columns} rows={rows} />
                </ArgonBox>
              </Card>
            </ArgonBox>
          </ArgonBox>


          <Modal
            isOpen={registerModal}
            style={modalSettings}
            shouldCloseOnEsc={true}
            preventScroll={false}
            role="dialog"
          >
            <div style={{ float: "right" }}>
              <ArgonButton
                color="warning"
                onClick={() => {
                  clearHooks()
                  setRegisterModal(false)
                }}
              >close</ArgonButton>
            </div>
            <br />
            <h2>{selectedUpdate === "" ? "Register Category" : "Update Category"}</h2>
            <br />
            <ArgonBox display="flex" justifyContent="space-between" alignItems="center">
              <div style={{ width: "49%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">Swahili Name</ArgonTypography>
                <ArgonInput onChange={(e) => setswahiliName(e.target.value)} value={swahiliName} placeholder="Enter Swahili Name" />
              </div>

              <div style={{ width: "49%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">English Name</ArgonTypography>
                <ArgonInput
                  onChange={(e) => {
                    setenglishName(e.target.value)
                  }}
                  value={englishName}
                  placeholder="Enter English Name"
                />
              </div>
            </ArgonBox>
            <br />
            <ArgonBox display="flex" justifyContent="space-between" alignItems="center">
              <div style={{ width: "49%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">Swahili Details</ArgonTypography>
                <ArgonInput onChange={(e) => setswahiliDetails(e.target.value)} value={swahiliDetails} placeholder="Enter Swahili Details" />
              </div>
              <div style={{ width: "49%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">English Details</ArgonTypography>
                <ArgonInput onChange={(e) => setenglishDetails(e.target.value)} value={englishDetails} placeholder="Enter English Details" />
              </div>
            </ArgonBox>
            <br />
            {isLoading === true ? (
              <Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />
            ) : (
              <>
                {selectedUpdate !== "" ? (
                  <ArgonButton onClick={() => updateCategoryFunction()} color="secondary">Update</ArgonButton>
                ) : (
                  <ArgonButton onClick={() => registerCategoryFunction()} color="primary">Register</ArgonButton>
                )}
              </>
            )}
          </Modal>
          <Footer />
        </DashboardLayout>
      )}
    </>
  );
}

export default Categories;
